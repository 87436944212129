import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{}
  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

 

  }

  render() {
    return (
      <div style={{padding:20}}>
            
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
          <h3 style={{color:'black'}}>{this.state.companyprofile.sacco_name}-{this.state.companyprofile.branchname}</h3>
          <h6 style={{color:'black'}}>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
          {
          this.state.companyprofile.RegisteredPhoneNumber2==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber2
          }
          {
          this.state.companyprofile.RegisteredPhoneNumber3==""?
          "":","+this.state.companyprofile.RegisteredPhoneNumber3
          }
          </h6>               
          <h5 style={{color:'black'}}>Email: {this.state.companyprofile.company_email}</h5>
          <h5 style={{color:'black'}}>Wesite: {this.state.companyprofile.website}</h5>
          <h5 style={{color:'black'}}>{this.state.companyprofile.box_number}</h5>
           <h4 style={{color:'black'}}>SLIP NO: {this.props.voucher_number}</h4>
           <h4 style={{color:'black'}}>DATE: {this.props.date}</h4>
           <h5 style={{color:'black'}}>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
           <h3 style={{strong:true,color:'black',fontWeight:'bolder'}}>DEPOSIT RECEIPT</h3>

           <table cellpadding="2" cellSpacing="2" border="1">
            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.AccountNumber}</td>
            </tr>

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>ACC NAME</td>
              <td style={{fontWeight:'bold'}}>{this.props.account.FullName}</td>
            </tr>  

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>DEPOSITED AMOUNT</td>
              <td style={{fontWeight:'bold'}}><CurrencyFormat  value={this.props.totalamount} displayType={'text'} thousandSeparator={true} /></td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>In Words</td>
              <td style={{fontWeight:'bold'}}>{ toWords.convert(this.props.totalamount)}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>PAID IN BY</td>
              <td style={{fontWeight:'bold'}}>{this.props.paidinby}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>SLIP NUMBER</td>
              <td style={{fontWeight:'bold'}}>{this.props.voucher_number}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>CONTACT</td>
              <td style={{fontWeight:'bold'}}>{this.props.transactorcontact}</td>
            </tr> 

            <tr style={{height:40,padding:1}} key={1}>
              <td style={{fontWeight:'bolder'}}>TELLER</td>
              <td style={{fontWeight:'bold'}}>{username}</td>
            </tr> 

           </table>


        
    </div>
    );
  }
}



class InterAccountTransfersSingle extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    voucher_number:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    companyprofile:{},
    accountchange:false,
    userprofile:{},
    transaction_details:'',
    tranferamount:0,
    interaccounttransfermode:'savings_reserve',
    supersave_profile:{},
    teebagoolo_profile:{},

  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

        this.setState({datarequested:false})
    })

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    this.setState({supersave_profile: {}})
    this.setState({teebagoolo_profile: {}})

    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
          this.setState({accountchange:false})
      })


  }

  handlepaidinbyChange= (e) => this.setState({ paidinby: e.target.value});
  handletransactorcontactChange= (e) => this.setState({ transactorcontact: e});


  //check empty objecty
  isEmptyObject=(obj)=>{
    for ( var name in obj ) {
      return false;
    }
    return true;
  }

//check super save maturity
  checksupersave_maturitydate=()=>{
    console.log(this.state.supersave_profile)

    const matuirity_str = this.state.supersave_profile.maturity_date;
    console.log("my end date"+matuirity_str)
  
    var dateMomentObjectone = moment(this.state.supersave_profile.maturity_date, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var currentrtdate=new Date()

    if (currentrtdate>=dateObjectone){
      return true;
    }else{
      return false;
    }

  }

//check teeba goolo maturity
  checkteebagoolo_maturitydate=()=>{
    console.log(this.state.teebagoolo_profile)

    const matuirity_str = this.state.teebagoolo_profile.term_maturitydate;
    console.log("my end date"+matuirity_str)
  
    var dateMomentObjectone = moment(this.state.teebagoolo_profile.term_maturitydate, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
    var dateObjectone = dateMomentObjectone.toDate();

    var currentrtdate=new Date()

    if (currentrtdate>=dateObjectone){
      return true;
    }else{
      return false;
    }

  }

    //calculate Netwithdraw
    calculateNetWithdraw=()=>{
      var netwithdraw=0
      var minimumaccountbal=0

      if (this.isEmptyObject(this.state.selectedaccountobject)===false){
         minimumaccountbal=Number(this.state.selectedaccountobject.minimum_account_balance)
         netwithdraw=Number(this.state.selectedaccountobject.UserBalance)-(minimumaccountbal)
      }

      return netwithdraw;
    }





  //submit the deposit now
  handleFormSubmit=(event) =>{
  console.log(this.state.supersave_profile)
    if(this.state.interaccounttransfermode==="savings_reserve" &&Number(this.state.tranferamount)>this.calculateNetWithdraw()){
      message.error("Savings Amount is less")
    }else if(this.state.interaccounttransfermode==="savings_shares" &&Number(this.state.tranferamount)>this.calculateNetWithdraw()){
      message.error(" Savings Amount is less")
    }else if (this.state.interaccounttransfermode==="reserve_savings" && Number(this.state.tranferamount)>Number(this.state.selectedaccountobject.savingsloan_security_balance)){
      message.error("Reserve account balance is low")

    }
    
    else{

      this.setState({datarequested:true})
      let form_data = new FormData();
      form_data.append('interaccounttransfermode',this.state.interaccounttransfermode);
      form_data.append('tranferamount', this.state.tranferamount);
      form_data.append('sacco', sacco);
      form_data.append('account', this.state.selectedaccountobject.id);
      form_data.append('username', username);
      form_data.append('userid', bizuserid);
      form_data.append('transaction_details', this.state.transaction_details);
      axios.post(serverconfig.backendserverurl+'/customqueries/memberinteraccountstransfer', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        this.setState({datarequested:false})
        message.info(res.data.message)
        if (res.data.message==="success"){
          this.setState({datasubmittedsuccessfully:true})
        }
      })
      .catch(error => console.log(error))

    }

  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            account={this.state.selectedaccountobject} 
            totalamount={this.state.tranferamount} 
            voucher_number={this.state.voucher_number} 
            paidinby={this.state.paidinby} 
            transactorcontact={this.state.transactorcontact}
            date={this.state.date}
            ref={el => (this.componentRef = el)} /> 
            </div>
            <Row >
                <Col xs="12" sm="6" lg="6">
                  <Card>
                  <Result
                  status="success"
                  title="Successfully inter account transfer "
                  extra={[
                    <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
                  ]}
                  />
                </Card>
                </Col>
            </Row>
          </div>
        )

      }else{
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="8" lg="8">
                    <Card>
                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Select Member for inter-tranfers</h4>
                    <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}                    
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>
                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }

                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> MEMBER INTERACCOUNT TRANSFER FORM</h3>
                  <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                    >

                  <FormItem 
                    label="Inter Account Transfer Mode"                      
                    >
                    <Select 
                    value={this.state.interaccounttransfermode} 
                    onChange={(val)=>{this.setState({interaccounttransfermode:val})}}
                    placeholder="Inter Account Tranfer Mode" >
                    <Option value='savings_reserve'>Savings to savings loan security(Reserve)</Option>     
                    <Option value='reserve_savings'>savings loan security(Reserve) to Savings</Option>
                    <Option value='savings_shares'>Savings to  Shares</Option>
                     </Select>
                  </FormItem>

                 <FormItem label={<h6 style={{fontWeight:'bold'}}>Tranfer Amount </h6>}
                  name="tranferamount"
                  rules={[
                    {
                      required: true,
                      message: 'Please input transfer amount',
                    },
                  ]}
                  >
                      <Input name="tranferamount"   placeholder="0" value={this.state.tranferamount} onChange={(e) => this.setState({ tranferamount: e.target.value})}/>
                  </FormItem>

                  <FormItem label={<h4 style={{fontWeight:'bold',color:'#154360'}}>Total Amount </h4>}
                    >
                      <h3 style={{fontWeight:'bolder'}}><CurrencyFormat  value={this.state.tranferamount} displayType={'text'} thousandSeparator={true} /></h3>
                  </FormItem>


                  <FormItem label={<h4 style={{fontWeight:'bold'}}>Transaction details.</h4>} >
                
                  <TextArea
                      placeholder="Transaction Details"
                      autoSize={{ minRows: 2, maxRows: 8 }}
                      value={this.state.transaction_details}
                      onChange={(val)=>{this.setState({transaction_details:val.target.value})}}
                    />
                  </FormItem>


                  <FormItem>
                    <Button  type="primary" htmlType="submit">Submit</Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                  </Col>

                  <Col xs="12" sm="4" lg="4">
                    <Card>
                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Savings Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Shares Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.sharesamount_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Savings security balance (Reserve):<CurrencyFormat value={this.state.selectedaccountobject.savingsloan_security_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                  
                    <h4>Loan account balance:<CurrencyFormat value={this.state.selectedaccountobject.loan_balance} displayType={'text'} thousandSeparator={true}  /> </h4>
                    <h4>Loan fine balance:<CurrencyFormat value={this.state.selectedaccountobject.loanfine_balance} displayType={'text'} thousandSeparator={true}  /> </h4>

                    <br></br>
                    </Card>
                  </Col>
               </Row>
            </div>
      
       </div>              
                      
       )
      }
    
    }
   
  }
}

export default InterAccountTransfersSingle; 
