import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Table, Input,Card, Button,Collapse,Spin,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
      chartattributes:{},
      companyaccounts:[],
      expenses_list:[],
      incomes_list:[]
    };


  
    componentDidMount(){
      if(localStorage.getItem("sacco")){
         sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
         bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      
      }else{
         sacco= ''
         bizuserid= ''
      }
      
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }


      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      let form_data = new FormData();
      form_data.append('sacco', sacco);
      axios.post(serverconfig.backendserverurl+'/customqueries/getchartofaccounts', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
      .then(res => {
        console.log(res.data)
        this.setState({chartattributes:res.data})
  
        this.setState({expenses_list:JSON.parse(res.data.expenses_list)  })
        this.setState({incomes_list:JSON.parse(res.data.incomes_list)   })
  
      })
      .catch(error => console.log(error))
  
      axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}&account_type=${'Primary'}`)
      .then(res => {
          this.setState({
              companyaccounts:res.data
          })
          this.setState({datarequested:false})
      })

      axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })
  
    }


    render() {
      return (
        <div style={{padding:20}}>
           <Row> 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.sacco_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                  {
                  this.state.companyprofile.RegisteredPhoneNumber2==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber2
                  }
                  {
                  this.state.companyprofile.RegisteredPhoneNumber3==""?
                  "":","+this.state.companyprofile.RegisteredPhoneNumber3
                  }
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Wesite: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>

                  </Col>

           </Row>
           <br></br>
           <h3 style={{display:'flex',justifyContent:'center',fontWeight:'bolder'}}>Institutional Chart Of Accounts</h3>
           <br></br>

           <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>ASSETS</th>
                      <th>
                       {
                          this.state.companyaccounts.map((item)=>{
                            return (
                              <h4>{item.account_name}:  {<CurrencyFormat value={item.current_balance} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }
                        <h4>Fixed Assets:  {<CurrencyFormat value={this.state.chartattributes.fixed_assets_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4>Total loan portfolio:  {<CurrencyFormat value={this.state.chartattributes.portifoliototal} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>LIABILITIES</th>
                      <th>
                      <h4>Savings total:  {<CurrencyFormat value={this.state.chartattributes.savings_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                      <h4>Savings (reserve):  {<CurrencyFormat value={this.state.chartattributes.savingsreserve_total} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>EQUITY</th>
                      <th>
                      <h4>Shares total:  {<CurrencyFormat value={this.state.chartattributes.shares_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                      <h4>Shares (reserve):  {<CurrencyFormat value={this.state.chartattributes.sharesreserve_total} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>INCOMES</th>
                      <th>
                      {
                          this.state.incomes_list.map((item)=>{
                            return (
                              <h4>{item.income_item}:  {<CurrencyFormat value={item.cat_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }

                      </th>
                    </tr>

                    <tr>
                      <th>EXPENSES</th>
                      <th>
                      {
                          this.state.expenses_list.map((item)=>{
                            return (
                              <h4>{item.expense_item}:  {<CurrencyFormat value={item.cat_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }
                      </th>
                    </tr>

                  </thead>                 
            </reactstrp.Table> 


            
                <br></br>
                <h3>Generated by: {CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)}</h3>
                <h3>Powered by: www.pitech.co.ug</h3>         
        </div>
      );
    }
  }


class ChartOfAccounts extends React.Component {

  state = {
   chartattributes:{},
   companyaccounts:[],
   expenses_list:[],
   incomes_list:[]

  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
      }else{
        sacco= ''
        bizuserid= ''
      }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getchartofaccounts', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
      console.log(res.data)
      this.setState({chartattributes:res.data})

      this.setState({expenses_list:JSON.parse(res.data.expenses_list)  })
      this.setState({incomes_list:JSON.parse(res.data.incomes_list)   })

    })
    .catch(error => console.log(error))


    axios.get(serverconfig.backendserverurl+`/api/companyaccounts/?sacco=${sacco}&account_type=${'Primary'}`)
    .then(res => {
        this.setState({
            companyaccounts:res.data
        })
        this.setState({datarequested:false})
    })

}


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {
    
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
            &nbsp;&nbsp;
          <ReportToPrint
          ref={el => (this.componentRef = el)} /> 
          </div>
          <h3 style={{display:'flex',justifyContent:'center',fontWeight:'bolder'}}>Institutional Chart Of Accounts</h3>

          <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>ASSETS</th>
                      <th>
                       {
                          this.state.companyaccounts.map((item)=>{
                            return (
                              <h4>{item.account_name}:  {<CurrencyFormat value={item.current_balance} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }
                        <h4>Fixed Assets:  {<CurrencyFormat value={this.state.chartattributes.fixed_assets_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                        <h4>Total loan portfolio:  {<CurrencyFormat value={this.state.chartattributes.portifoliototal} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>LIABILITIES</th>
                      <th>
                      <h4>Savings total:  {<CurrencyFormat value={this.state.chartattributes.savings_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                      <h4>Savings (reserve):  {<CurrencyFormat value={this.state.chartattributes.savingsreserve_total} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>EQUITY</th>
                      <th>
                      <h4>Shares total:  {<CurrencyFormat value={this.state.chartattributes.shares_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                      <h4>Shares (reserve):  {<CurrencyFormat value={this.state.chartattributes.sharesreserve_total} displayType={'text'} thousandSeparator={true}/>}</h4>

                      </th>
                    </tr>

                    <tr>
                      <th>INCOMES</th>
                      <th>
                      {
                          this.state.incomes_list.map((item)=>{
                            return (
                              <h4>{item.income_item}:  {<CurrencyFormat value={item.cat_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }

                      </th>
                    </tr>

                    <tr>
                      <th>EXPENSES</th>
                      <th>
                      {
                          this.state.expenses_list.map((item)=>{
                            return (
                              <h4>{item.expense_item}:  {<CurrencyFormat value={item.cat_total} displayType={'text'} thousandSeparator={true}/>}</h4>
                            );
                          })
                        }
                      </th>
                    </tr>

                  </thead>                 
            </reactstrp.Table> 

            <div style={{display:'flex',justifyContent:'flex-start',alignSelf:'flex-start',alignItems:'flex-start'}}>
            <ReactToPrint
            trigger={() => 
            <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
            Print
            </Button>
            }
            content={() => this.componentRef}
            />
            </div>

        </div>
    )

    }

  }
}

export default ChartOfAccounts; 
