import React from 'react';
import IDTypeForm from '../components/IDTypeForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Spin,Modal,Form,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,EditOutlined,DeleteOutlined,FundViewOutlined,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

const dateFormat = 'DD/MM/YYYY';
const FormItem=Form.Item;


var token= ''
var sacco= ''

class SalaryRequistionList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    salaryrequisition: [],
    datarequested:true,
    selectedreqobject:{},
    detailmodalvisible:false,
    requistionitems:[],
    updatmodalvisible:false,
    
    salaryamount:0,
    salaryadvance:0,
    selectedadvanceitem:{},
    expensecategories:[],
    expensecategory:'',
    paymonth:'',
    nssfamount:0



  };

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/saccos/${sacco}`)
    .then(res => {
      console.log(res.data)

        //this.setState({selectedreqobject:record})
        if(res.data.salary_list==null ){
          console.log("no data")
        }else{
          this.setState({requistionitems:JSON.parse(res.data.salary_list)})

        }
    })



    axios.get(serverconfig.backendserverurl+`/api/expensecategories/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          expensecategories:res.data
        })
        this.setState({datarequested:false})

    })
}

getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/idtypes/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


  //calculate net salary
  caltotalnetsalary=()=>{
    var total=0

    this.state.requistionitems.map((item)=>{
      total+=Number(item.netpay)
    })
    return total;
  }

  //calculate 
  caltotalsalary=()=>{
    var total=0

    this.state.requistionitems.map((item)=>{
      total+=Number(item.salaryamount)
    })
    return total;
  }

  caltotaladvance=()=>{
    var total=0
    this.state.requistionitems.map((item)=>{
      total+=Number(item.salaryadvance)
    })
    return total;
  }


  render() {
    


    const listcolumns = [
      {
        title: 'AccountName',
        dataIndex: 'accountname',
        key: 'id',
      },
      {
        title: 'AccountNumber',
        dataIndex: 'accountnumber',
        key: 'id',
      },
      {
        title: 'Salary Amount',
        dataIndex: 'salaryamount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Salary Advance',
        dataIndex: 'salaryadvance',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Nssf',
        dataIndex: 'nssfamount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'Net pay',
        dataIndex: 'netpay',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit </p>} title="">
          <EditOutlined style={{color:'blue'}}
          onClick={()=>{
            this.setState({updatmodalvisible:true})

            this.setState({selectedadvanceitem:record})
            this.setState({salaryamount:Number(record.salaryamount)})
            this.setState({salaryadvance:Number(record.salaryadvance)})
            this.setState({nssfamount:Number(record.nssfamount)})

          }}
          />
        </Popover>
        </p>,
      }
      
    ];


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <h3>Salary receivers  list</h3>

          <Table 
          columns={listcolumns}
          pagination={{showQuickJumper:true,showSizeChanger:true }}
          dataSource={this.state.requistionitems}
          scroll={{ x: 1000 }}
          bordered/>

          <div style={{display:'flex',flexDirection:'row'}}>
          <h4>Salary: <CurrencyFormat value={this.caltotalsalary()} displayType={'text'} thousandSeparator={true}  />  | Advance: <CurrencyFormat value={this.caltotaladvance()} displayType={'text'} thousandSeparator={true}  />  |  Net Salary: <CurrencyFormat value={this.caltotalnetsalary()} displayType={'text'} thousandSeparator={true}  /></h4>

          </div>


          <h3>Make salary payment</h3>
          <FormItem label="Expense Category"
            name="expensecategory"
            rules={[
              {
                required: true,
                message: 'Please Select Salary Expense category',
              },
            ]}
          >
              <Select placeholder="Expense Category" style={{ width: 120 }} value={this.state.expensecategory} onChange={(val)=>{this.setState({expensecategory:val})}} >
                {this.state.expensecategories.map(
                  (ctgry)=>(
                    <Option value={ctgry.id}>{ctgry.category_name}</Option>
                  ))}
              </Select>
          </FormItem>

          <FormItem
          label="Pay month"
          name="paymonth"
          rules={[
            {
              required: true,
              message: 'Please enter pay month',
            },
          ]}
          >
          <Input name="paymonth"  placeholder="Pay month" value={this.state.paymonth} onChange={(val)=>{this.setState({paymonth:val.target.value})}} />

          </FormItem>

  
        {
          this.state.requistionitems.length>0?
          <Button  type="primary" htmlType='button'
            
          onClick={()=>{

          if(this.state.expensecategory===undefined){
            message.info("Please select expense category")
          }else{
            this.setState({datarequested:true})

            //get last slip number
            let form_data = new FormData();

            form_data.append('date', moment().format(dateFormat).toString());
            form_data.append('sacco', sacco);
            form_data.append('requistionrequests', JSON.stringify(this.state.requistionitems));
            form_data.append('expense_category', this.state.expensecategory);
            form_data.append('paymonth', this.state.paymonth);

            axios.post(serverconfig.backendserverurl+'/customqueries/confirmsalarydeposits', form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
              })
            .then(res => {
              message.info("Successfully deposited money on all accounts")

              this.setState({datarequested:false})
              window.location.reload(false)

            })
            .catch(error => console.log(error))

          }

          }}
          
          >Confirm Salary Deposits</Button>
          :
          null
        }


          <Modal    
            visible={this.state.updatmodalvisible}
            title="Requistion Update"
            onCancel={()=>{this.setState({updatmodalvisible:false})}}
            footer={[
              <Button key="back" onClick={()=>{this.setState({updatmodalvisible:false})}}>
                Cancel
              </Button>
              ]}
              >

            <FormItem label="Salary Amount"
            >
              <Input name="salaryamount"  placeholder="0.00" value={this.state.salaryamount} onChange={(val)=>{this.setState({salaryamount:val.target.value})}} />
            </FormItem>

            <FormItem label="Salary Advance"
            >
              <Input name="salaryadvance"  placeholder="0.00" value={this.state.salaryadvance} onChange={(val)=>{this.setState({salaryadvance:val.target.value})}} />
            </FormItem>


            <Button key="back" type='primary' onClick={()=>{

              const elementsIndex = this.state.requistionitems.findIndex(element => element.memberid == this.state.selectedadvanceitem.memberid)

              let newArray = [...this.state.requistionitems]
            
              newArray[elementsIndex] = {...newArray[elementsIndex], 

                'salaryamount':this.state.salaryamount,
                'salaryadvance':this.state.salaryadvance,
                'netpay':Number(this.state.salaryamount)-(Number(this.state.salaryadvance)+Number(this.state.nssfamount))
              }
            
              this.setState({
                requistionitems: newArray,
              });

              this.setState({updatmodalvisible:false})

              }}>
                  Update
            </Button>
            </Modal>
        </div>
    )


    }
   
  }
}

export default SalaryRequistionList; 
