import React,{ useRef } from 'react';
import StaffForm from '../components/StaffForm'
import axios from 'axios'
import { Tooltip,Popover,Result,Table,Popconfirm,Input, Button,Collapse,Switch,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { DeleteOutlined,SearchOutlined,CameraOutlined,PlusOutlined,EditOutlined,PlusSquareFilled,SaveOutlined,LoadingOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import * as reactstrp from 'reactstrap';
import { ToWords } from 'to-words';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const toWords = new ToWords();

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});




var token= ''
var sacco= ''
var username=''
var bizuserid= ''


class SalaryRequistionView extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    selectedaccount:'',
    selectedaccountobject:{},
    members:[],
    datarequested:true,
    totalamount:0,
    slipnumber:'',
    paidinby:'',
    transactorcontact:'',
    previoustransactions:[],
    datasubmittedsuccessfully:false,
    lastslipnumber:0,
    salaryamount:0,
    salaryadvance:0,
    
    companyprofile:{},
    accountchange:false,
    userprofile:{},

    reqlist:[],
    salarymonth:'',
    deductnssf:false,
    ts_anupdate:false


  }

  callback(key) {
    console.log(key);
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    if(localStorage.getItem("bizuserid")){
      bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
   }else{
      bizuserid= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    let form_data1 = new FormData();
    form_data1.append('userid', bizuserid);
    
    //get the user profile here
    axios.post(serverconfig.backendserverurl+'/customqueries/get_user_profile',form_data1,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({
          userprofile:res.data
        })
     
    })


    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({companyprofile: res.data})

        console.log(res.data)

        if (res.data.salary_list!=undefined){
          //this set list here
           this.setState({reqlist:JSON.parse(res.data.salary_list)})
        }


    })

    axios.get(serverconfig.backendserverurl+`/api/members/?sacco=${sacco}`)
    .then(res => {
        this.setState({
          members:res.data
        })

    })

    //get last slip number
    let form_data = new FormData();
    form_data.append('sacco', sacco);
    axios.post(serverconfig.backendserverurl+'/customqueries/getsaccolastslipnumber', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res => {
        this.setState({lastslipnumber:res.data.lastnumber})
        this.setState({slipnumber:Number(res.data.lastnumber)+1})
        this.setState({datarequested:false})
    })
    .catch(error => console.log(error))

  }

  
  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }

  //handle client change
  handleselectedaccountChange= (e) =>{
    this.setState({accountchange:true})
    this.setState({ selectedaccount: e});
    //make query for last transactions
    axios.get(`${serverconfig.backendserverurl}/api/members/${e}`)
      .then(res => {  
          this.setState({selectedaccountobject: res.data})
      })

      axios.get(`${serverconfig.backendserverurl}/api/transactions/?transaction_account=${e}`)
      .then(res => {  
          this.setState({previoustransactions: res.data.slice(0, 3)})
          console.log(res.data)
          this.setState({accountchange:false});
      })

      this.setState({slipnumber:Number(this.state.lastslipnumber)+1})

  }

 
  handlesalaryamountChange= (e) => this.setState({ salaryamount: e});
  handlesalaryadvanceChange= (e) => this.setState({ salaryadvance: e});
  

    //calculate net salary
    caltotalnetsalary=()=>{
      var total=0
  
      this.state.reqlist.map((item)=>{
        total+=Number(item.netpay)
      })
      return total;
    }
  
    //calculate 
    caltotalsalary=()=>{
      var total=0
  
      this.state.reqlist.map((item)=>{
        total+=Number(item.salaryamount)
      })
      return total;
    }
  
    caltotaladvance=()=>{
      var total=0
      this.state.reqlist.map((item)=>{
        total+=Number(item.salaryadvance)
      })
      return total;
    }
  

    removeItem=(id)=>{
      console.log(id)
      this.setState({ reqlist:  [...this.state.reqlist.filter(todo => todo.key
        !==id)]});
    }

    //calcultae nssf
    calculatenssf=()=>{
      var total=0

      if(this.state.deductnssf===true && Number(this.state.companyprofile.nssfstaffpercentage)>0){
        total=(Number(this.state.companyprofile.nssfstaffpercentage)/100)*Number(this.state.salaryamount)
      }

      return total;
    }
  


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

    const listcolumns = [
      {
        title: 'AccountName',
        dataIndex: 'accountname',
        key: 'id',
      },
      {
        title: 'AccountNumber',
        dataIndex: 'accountnumber',
        key: 'id',
      },
      {
        title: 'Salary Amount',
        dataIndex: 'salaryamount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'Deduct NSSF?',
        dataIndex: 'id',
        key: 'id',
        render: (text,record)=><p>{record.deductnssf?"Yes":"No"}</p>
      },

      {
        title: 'ACTION',
        dataIndex: 'key',
        key: 'key',
        render: (text,record) =>
        <p>

{ /*       <Popover content={<p>Click here to edit receiver</p>} title="Activate">
        <EditOutlined style={{color:'green'}} 
        onClick={()=>{
          this.setState({ selectedaccount: record.accountobj.id});
          this.setState({salaryamount:record.salaryamount})
          this.setState({deductnssf:record.deductnssf})
          this.setState({selectedaccountobject:record.accountobj})
          this.setState({ts_anupdate:true})
        }}/>
        </Popover>

        &nbsp;&nbsp;&nbsp; */}
        <Popover content={<p>Click here to delete item</p>} title="Delete Item">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
        <CloseCircleFilled style={{color:'red'}} />
        </Popconfirm>       
         </Popover>
        </p>
      },

      
    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
     
        return(
          <div>
            <div>
  
              <Row >
                  <Col xs="12" sm="6" lg="5">
                    <Card>

                    <h4 style={{fontStyle:'oblique',fontWeight:'bolder'}}>Select Account to add to list </h4>
                    <FormItem label="Account">
                        <Select 
                        placeholder="Account No | Name" 
                        style={{  }} 
                        value={this.state.selectedaccount}
                        onChange={this.handleselectedaccountChange} 
                        showSearch
                        optionFilterProp="children"
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onSearch={this.onSearch}
                                             
                        >
                          {this.state.members.map(
                            (accnt)=>(
                              <Option value={accnt.id}>{accnt.AccountNumber} | {accnt.FullName}</Option>
                            ))}
                        </Select>
                    </FormItem>

                    {
                      this.state.accountchange?
                      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
                      <Spin indicator={antIcon} />
                      </div>
                      :
                      null
                    }


                    <h3 style={{fontWeight:'bold',color:'#154360'}}>Account Details</h3>
                    <h4>Account Number:{this.state.selectedaccountobject.AccountNumber}</h4>
                    <h4>Account Contact:{this.state.selectedaccountobject.RegisteredPhoneNumber1}</h4>
                    <h4>Account Name:{this.state.selectedaccountobject.FullName}</h4>
                    <h4>Account Balance:<CurrencyFormat value={this.state.selectedaccountobject.UserBalance} displayType={'text'} thousandSeparator={true}  /> UGX</h4>

                    <br></br>
                    <h3 style={{fontWeight:'bold',alignSelf:'center',color:'#154360'}}> REQUISITION FORM</h3>
                    <Form
                      onFinish={(event) => {

                        if (this.state.selectedaccountobject.id===undefined){
                          message.info("Please select account number")
                        }else{







                          var newitem={
                            'key':this.state.selectedaccountobject.id,
                            'memberid':this.state.selectedaccountobject.id,
                            'accountname':this.state.selectedaccountobject.FullName,
                            'accountnumber':this.state.selectedaccountobject.AccountNumber,
                            'accountobj':this.state.selectedaccountobject,
                            'salaryamount':this.state.salaryamount,
                            'salaryadvance':0,
                            'netpay':this.state.salaryamount-this.calculatenssf(),
                            'nssfamount':this.calculatenssf(),
                            'deductnssf':this.state.deductnssf,
                          }
            
                          //add to shedule object
                            this.setState({
                            reqlist: [...this.state.reqlist, newitem]
                            });
  
                            this.setState({salaryamount:0})
                            this.setState({selectedaccountobject:{}})
                            this.setState({selectedaccount:''})
                            this.setState({deductnssf:false})
                          
                        }

                      }}
                    >

                    <FormItem label={<h6 style={{fontWeight:'bold'}}>Salary Amount </h6>}
                    name="salaryamount"
                    rules={[
                      {
                        required: false,
                        message: 'Please input amount',
                      },
                    ]}
                    
                    >
                      <InputNumber
                        style={{ width: 200 }} 
                        defaultValue={0.0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        onChange={this.handlesalaryamountChange}
                      />    
                                  
                    </FormItem>


                    <FormItem name="deductnssf" label="Deduct Nssf" >
                        <Switch
                            checked={this.state.deductnssf}
                            onChange={val=>{this.setState({deductnssf:val})}}
                            checkedChildren="ON"
                            unCheckedChildren="OFF"
                        />
                    </FormItem>

                  <FormItem>
                    <Button  type="primary" htmlType="submit"> {this.state.ts_anupdate===false?"Add To List":"Upate"}  </Button>
                  </FormItem>
                  </Form>
  
                  </Card>
                   
                  </Col>
  

                  <Col xs="12" sm="6" lg="6">
                  <Card>
                    <h3>Salary receivers list</h3>

                    <Table 
                    columns={listcolumns}
                    pagination={{showQuickJumper:true,showSizeChanger:true }}
                    dataSource={this.state.reqlist}
                    scroll={{ x: 1000 }}
                    bordered/>

                    <h4>Salary total: <CurrencyFormat value={this.caltotalsalary()} displayType={'text'} thousandSeparator={true}  /></h4>
                 

                      <Button  type="primary" htmlType='button'
                      
                      onClick={()=>{

                      this.setState({datarequested:true})

                      //get last slip number
                      let form_data = new FormData();

                      form_data.append('date', moment().format(dateFormat).toString());
                      form_data.append('sacco', sacco);
                      form_data.append('requistionrequests', JSON.stringify(this.state.reqlist));

                      axios.post(serverconfig.backendserverurl+'/customqueries/createsalaryreceivers', form_data, {
                        headers: {
                          'content-type': 'multipart/form-data'
                        }
                        })
                      .then(res => {
                          window.location.reload(false)
                          this.setState({datarequested:false})
                      })
                      .catch(error => console.log(error))

                      }}
                      
                      >Submit list</Button>
                  
                  </Card>
                   
                </Col>

            </Row>
  
            </div>
             
       </div>              
                      
        )
      
    }
   
  }
}

export default SalaryRequistionView; 
