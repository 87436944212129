import React from 'react';
import axios from 'axios';
import { Card,Avatar} from 'antd';
import { Form, Input, Button,Select,Spin } from 'antd';
import {
    Col,
    Row,
  } from 'reactstrap';
import AssetForm from '../components/AssetForm'
import { UserOutlined,LoadingOutlined } from '@ant-design/icons';
import * as serverconfig from '../serverconn'
const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class LoanTypeDetail extends React.Component{

    state={
        loantype: {},
        loan_type_name:'',
        loan_form_fees:0,
        loan_assessment_fees:0,
        loan_insurance_fee:0,
        loan_commitment_fee:0,
        loan_agreement_fees:0,
        reserve_fees:0,
        interest_rate:0,
        maximum_loan_amount:0,
        maximum_loan_amount_first_time_borrower:0,
        maximum_period:0,
        maximum_period_first_time_borrower:0,
        percentage_payment_of_currentloan_before_top_up:0,

        generate_dailyschedule:false,
        loan_repaymentmode:'perschedule',
        interestrate_type:'flatinterest',
        loan_primary_schedule:'monthly',
        no_of_times_collateral_againstloanamount:0,
        photograph_fee:0,
        no_of_approvers:0,

        loanfine_grace_period_days:0,
        daily_loanfinepercentage:0,
        percentageof_remainginginterest_on_earlypayoff:0,
        percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff:0,

    }
    


    componentDidMount(){
      if(localStorage.getItem("token")){
         token= localStorage.getItem("token")
      }else{
         token= ''
      }

      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

        const loanTypeID=this.props.match.params.loanTypeID;
        axios.get(`${serverconfig.backendserverurl}/api/loantypes/${loanTypeID}`)
        .then(res => {  
            this.setState({loantype: res.data})

            this.setState({loan_type_name: res.data.loan_type_name})
            this.setState({loan_form_fees: res.data.loan_form_fees})
            this.setState({loan_assessment_fees: res.data.loan_assessment_fees})
            this.setState({loan_insurance_fee: res.data.loan_insurance_fee})
            this.setState({loan_commitment_fee: res.data.loan_commitment_fee})
            this.setState({loan_agreement_fees: res.data.loan_agreement_fees})
            this.setState({reserve_fees: res.data.reserve_fees})
            this.setState({interest_rate: res.data.interest_rate})

            this.setState({maximum_loan_amount: res.data.maximum_loan_amount})
            this.setState({maximum_loan_amount_first_time_borrower: res.data.maximum_loan_amount_first_time_borrower})
            this.setState({maximum_period: res.data.maximum_period})
            this.setState({maximum_period_first_time_borrower: res.data.maximum_period_first_time_borrower})
            this.setState({percentage_payment_of_currentloan_before_top_up: res.data.percentage_payment_of_currentloan_before_top_up})

            this.setState({generate_dailyschedule: res.data.generate_dailyschedule})
            this.setState({loan_repaymentmode: res.data.loan_repaymentmode})
            this.setState({interestrate_type: res.data.interestrate_type})
            this.setState({loan_primary_schedule: res.data.loan_primary_schedule})
            this.setState({photograph_fee: res.data.photograph_fee})
            this.setState({no_of_times_collateral_againstloanamount: res.data.no_of_times_collateral_againstloanamount})
            this.setState({no_of_approvers: res.data.no_of_approvers})
          
            this.setState({loanfine_grace_period_days: res.data.loanfine_grace_period_days})
            this.setState({daily_loanfinepercentage: res.data.daily_loanfinepercentage})
            this.setState({percentageof_remainginginterest_on_earlypayoff: res.data.percentageof_remainginginterest_on_earlypayoff})
            this.setState({percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff: res.data.firstinst_interest_incase_no_dueinterest_onloanpayoff})

            
            this.setState({datarequested:false})

        })
    }

    //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    const loanTypeID=this.props.match.params.loanTypeID;
    let form_data = new FormData();

    form_data.append('loan_type_name', this.state.loan_type_name);
    form_data.append('loan_form_fees', this.state.loan_form_fees);
    form_data.append('loan_assessment_fees', this.state.loan_assessment_fees);
    form_data.append('loan_insurance_fee', this.state.loan_insurance_fee);
    form_data.append('loan_commitment_fee', this.state.loan_commitment_fee);
    form_data.append('loan_agreement_fees', this.state.loan_agreement_fees);
    form_data.append('reserve_fees', this.state.reserve_fees);
    form_data.append('interest_rate', this.state.interest_rate);
    form_data.append('maximum_loan_amount', this.state.maximum_loan_amount);
    form_data.append('maximum_loan_amount_first_time_borrower', this.state.maximum_loan_amount_first_time_borrower);
    form_data.append('maximum_period', this.state.maximum_period);
    form_data.append('maximum_period_first_time_borrower', this.state.maximum_period_first_time_borrower);
    form_data.append('percentage_payment_of_currentloan_before_top_up', this.state.percentage_payment_of_currentloan_before_top_up);

    form_data.append('generate_dailyschedule', false);
    form_data.append('loan_repaymentmode', this.state.loan_repaymentmode);
    form_data.append('interestrate_type', this.state.interestrate_type);
    form_data.append('loan_primary_schedule', this.state.loan_primary_schedule);
    form_data.append('photograph_fee', this.state.photograph_fee);
    form_data.append('no_of_times_collateral_againstloanamount', this.state.no_of_times_collateral_againstloanamount);
    form_data.append('no_of_approvers', this.state.no_of_approvers);

    form_data.append('loanfine_grace_period_days', this.state.loanfine_grace_period_days);
    form_data.append('daily_loanfinepercentage', this.state.daily_loanfinepercentage);
    form_data.append('percentageof_remainginginterest_on_earlypayoff', this.state.percentageof_remainginginterest_on_earlypayoff);
    form_data.append('firstinst_interest_incase_no_dueinterest_onloanpayoff', this.state.percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff);

    axios.put(serverconfig.backendserverurl+`/api/loantypes/${loanTypeID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>this.setState({datarequested:false}),window.location.reload(false)

  )
  .catch(error => console.log(error))

  }


  handleloan_type_nameChange= (e) => this.setState({ loan_type_name: e.target.value});
  handleloan_form_feesChange= (e) => this.setState({ loan_form_fees: e.target.value});
  handleloan_assessment_feesChange= (e) => this.setState({ loan_assessment_fees: e.target.value});
  handleloan_insurance_feeChange= (e) => this.setState({ loan_insurance_fee: e.target.value});
  handleloan_commitment_feeChange= (e) => this.setState({ loan_commitment_fee: e.target.value});
  handleloan_agreement_feesChange= (e) => this.setState({ loan_agreement_fees: e.target.value});
  handlereserve_feesChange= (e) => this.setState({ reserve_fees: e.target.value});
  handleinterest_rateChange= (e) => this.setState({ interest_rate: e.target.value});
  handlemaximum_loan_amountChange= (e) => this.setState({ maximum_loan_amount: e.target.value});
  handlemaximum_loan_amount_first_time_borrowerChange= (e) => this.setState({ maximum_loan_amount_first_time_borrower: e.target.value});
  handlemaximum_periodChange= (e) => this.setState({ maximum_period: e.target.value});
  handlemaximum_period_first_time_borrowerChange= (e) => this.setState({ maximum_period_first_time_borrower: e.target.value});
  handlepercentage_payment_of_currentloan_before_top_upChange= (e) => this.setState({ percentage_payment_of_currentloan_before_top_up: e.target.value});


    render(){

        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin size="large">
                <div className="content" />
              </Spin>                 </div>
            )
      
          }else{

            return(
                <div>
        


        <Card 
          style={{padding:10}}
          hoverable>
            <h4>Loan Type Details / Update</h4>
            <Form
                onFinish={(event) => this.handleFormSubmit(event)} 
                >
            <FormItem label="Loan Type Name">
              <Input name="loan_type_name"  placeholder="Put a name here." value={this.state.loan_type_name} onChange={this.handleloan_type_nameChange} />
            </FormItem>

            <FormItem label="Loan Form Fees">
              <Input name="loan_form_fees"  placeholder="Block Figure eg 1000" value={this.state.loan_form_fees} onChange={this.handleloan_form_feesChange} />
            </FormItem>

  
            <FormItem label="Loan Assessment Fees (Processing fees)">
              <Input name="loan_assessment_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_assessment_fees} onChange={this.handleloan_assessment_feesChange} />
            </FormItem>
  
            <FormItem label="Loan Insurance Fees (Loan protection fees)">
              <Input name="loan_insurance_fee"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.loan_insurance_fee} onChange={this.handleloan_insurance_feeChange} />
            </FormItem>


            <FormItem label="Loan Reserve Fee (savings loan security)">
              <Input name="reserve_fees"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.reserve_fees} onChange={this.handlereserve_feesChange} />
            </FormItem>

            <FormItem label="Photograph Fees"         
            >
              <Input name="photograph_fee"  placeholder="Block Figure eg 1000" value={this.state.photograph_fee} onChange={(val)=>{this.setState({photograph_fee:val.target.value})}} />
            </FormItem>


            <FormItem label="Interest Rate">
              <Input name="interest_rate"  placeholder="Enter converted value eg 0.01 for 1%" value={this.state.interest_rate} onChange={this.handleinterest_rateChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount">
              <Input name="maximum_loan_amount"  placeholder="0.00" value={this.state.maximum_loan_amount} onChange={this.handlemaximum_loan_amountChange} />
            </FormItem>

            <FormItem label="Maximum Loan Amount (First Time Borrower)">
              <Input name="maximum_loan_amount_first_time_borrower"  placeholder="0.00" value={this.state.maximum_loan_amount_first_time_borrower} onChange={this.handlemaximum_loan_amount_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Maximum Loan Period">
              <Input name="maximum_period"  placeholder="0" value={this.state.maximum_period} onChange={this.handlemaximum_periodChange} />
            </FormItem>

            <FormItem label="Maximum Loan Period (First Time Borrower)">
              <Input name="maximum_period_first_time_borrower"  placeholder="0.00" value={this.state.maximum_period_first_time_borrower} onChange={this.handlemaximum_period_first_time_borrowerChange} />
            </FormItem>


            <FormItem label="Percentage Payment Before Top App">
              <Input name="percentage_payment_of_currentloan_before_top_up"  placeholder="Enter converted value eg 0.5 for 50%" value={this.state.percentage_payment_of_currentloan_before_top_up} onChange={this.handlepercentage_payment_of_currentloan_before_top_upChange} />
            </FormItem>


            {/* new formulas  */ }
            <FormItem 
                label="Interest rate Type"
                >
                    <Select 
                    value={this.state.interestrate_type} 
                    onChange={(val)=>{this.setState({interestrate_type:val})}}
                    placeholder="Interest rate Type" >
                      <Option value='flatinterest'>Flat Interest</Option>
                      <Option value='reducingBalanceInterest'>Reducing Balance</Option>
                    </Select>
            </FormItem>

            <FormItem 
                label="Loan Primary Schedule (Interest per day/weekly/monthly)"
                >
                    <Select 
                    value={this.state.loan_primary_schedule} 
                    onChange={(val)=>{this.setState({loan_primary_schedule:val})}}
                    placeholder="Loan Primary Schedule" >
                      <Option value='monthly'>Monthly</Option>
                      <Option value='weekly'>Weekly</Option>
                      <Option value='daily'>Daily</Option>
                    </Select>
            </FormItem>
 

            <FormItem 
            label="Loan RePayment Mode"
            >
              <Select 
              value={this.state.loan_repaymentmode} 
              onChange={(val)=>{this.setState({loan_repaymentmode:val})}}
              placeholder="Payment Mode" >
                    <Option value='daily'>Daily</Option>
                    <Option value='perschedule'>Per Schedule</Option>
              </Select>
            </FormItem>


            <FormItem label="loan limit (Collateral times amount requested)"         
            >
              <Input name="no_of_times_collateral_againstloanamount"  placeholder="x" value={this.state.no_of_times_collateral_againstloanamount} onChange={(val)=>{this.setState({no_of_times_collateral_againstloanamount:val.target.value})}} />
            </FormItem>

            <FormItem label="Number of loan approvers"       
            >
              <Input  placeholder="0" value={this.state.no_of_approvers} onChange={(val)=>{this.setState({no_of_approvers:val.target.value})}} />
            </FormItem>
         
            <FormItem label="Loan fine grace period (Days)"       
            >
              <Input  placeholder="0" value={this.state.loanfine_grace_period_days} onChange={(val)=>{this.setState({loanfine_grace_period_days:val.target.value})}} />
            </FormItem>

            <FormItem label="loan fine percentage charge"         
            >
              <Input  placeholder="0" value={this.state.daily_loanfinepercentage} onChange={(val)=>{this.setState({daily_loanfinepercentage:val.target.value})}} />
            </FormItem>

            <FormItem label="Percentage of remaining interest on early loan payoff"         
            >
              <Input  placeholder="0" value={this.state.percentageof_remainginginterest_on_earlypayoff} onChange={(val)=>{this.setState({percentageof_remainginginterest_on_earlypayoff:val.target.value})}} />
            </FormItem>

            <FormItem label="Percentage on first installment interest in case of no due interest (Early payoff)"         
            >
              <Input  placeholder="0" value={this.state.percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff} onChange={(val)=>{this.setState({percentageonfirstinst_interest_incase_no_dueinterest_onloanpayoff:val.target.value})}} />
            </FormItem>


            <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
            </FormItem>
            </Form>
          
          </Card>
          
        </div>

        )
        }

        
    }
}

export default LoanTypeDetail; 