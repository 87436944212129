import React from 'react';
import { Route } from 'react-router-dom';
import Index from './views/containers/index'
import IDTypeList from './views/containers/IDListView'
import IDTypeDetail from './views/containers/IDDetailView'
import ExpenseCategoryList from './views/containers/ExpenseCategorysListView'
import ExpenseCategoryDetail from './views/containers/ExpenseCategoryDetailView'
import StaffRoleList from './views/containers/StaffRolesListView'
import StaffRoleDetail from './views/containers/StaffRoleDetailView'
import ExpenseList from './views/containers/ExpenseListView'
import ExpenseDetail from './views/containers/ExpenseDetailView'
import AssetList from './views/containers/AssetListView'
import AssetDetail from './views/containers/AssetDetailView'
import AssetCategoryList from './views/containers/AssetCategorysListView'
import AssetCategoryDetail from './views/containers/AssetCategoryDetailView'
import StaffList from './views/containers/StaffListView'
import StaffDetail from './views/containers/StaffDetailView'
import AccountTypeList from './views/containers/AccountTypeListView'
import AccountTypeDetail from './views/containers/AccountTypeDetailView'
import LoanTypeList from './views/containers/LoanTypeListView'
import LoanTypeDetail from './views/containers/LoanTypeDetailView'
import NonWorkingDayList from './views/containers/NonWorkingDayListView'
import NonWorkingDayDetail from './views/containers/NonWorkingDayDetailView'
import IncomeCategoryList from './views/containers/IncomeCategorysListView'
import IncomeCategoryDetail from './views/containers/IncomeCategoryDetailView'
import CashAtBankList from './views/containers/CashAtBankListView'
import CashAtBankDetail from './views/containers/CashAtBankDetailView'
import ExternalLoanList from './views/containers/ExternalLoanListView'
import ExternalLoanDetail from './views/containers/ExternalLoanDetailView'
import MemberList from './views/containers/MemberListView'
import MemberDetail from './views/containers/MemberDetailView'
import AccountDepositView from './views/containers/AccountDepositView'
import AccountWithdrawView from './views/containers/AccountWithdrawView'
import LoanApplicationForm from './views/components/LoanApplicationForm'
import  LoanCalculator from './views/components/LoanCalculator'
import  LoanApplicationsList from './views/containers/LoanApplicationListView'
import  LoanApplicationDetail from './views/containers/LoanApplicationDetailView'
import  LoanAssessmentForm from './views/components/LoanAssessmentForm'
import  LoanAssessmentList from './views/containers/LoanAssessmentListView'
import  LoanAppovalForm from './views/components/LoanApprovalForm'
import  LoanApprovalList from './views/containers/LoanApprovalListView'
import  LoanIssuanceForm from './views/components/LoanIssuanceForm'
import  LoanIssuanceList from './views/containers/LoanIssuancesListView'
import  LoanIssueDetail from './views/containers/LoanIssueDetails'
import  LoanRepaymentView from './views/containers/LoanRepaymentView'
import  IncomeList from './views/containers/IncomeListView'
import IncomeDetail from './views/containers/IncomeDetailView'
import LoanGuarantorsList from './views/containers/LoanGuarantorsListView'
import LoanConsentsList from './views/containers/LoanConsentsListView'
import OpeningItemList from './views/containers/AccountOpeningItemsListView'
import TillSheetReport from './views/containers/TillSheetReportView'
import TransactionReport from './views/containers/TransactionReportView'
import MemberSavingsReport from './views/containers/MemberSavingsReportView'
import IncomeStatementReport from './views/containers/IncomeStatementView'
import DayLoanReport from './views/containers/DayLoanReportView'
import OutStandingLoanReport from './views/containers/OutStandingLoanReportView'
import LoanRepaymentStatusReport from './views/containers/LoanRepaymentStatusReportView'
import LoanDefaultsReport from './views/containers/LoanDefaultsReportView'
import LoanAgingReport from './views/containers/LoanAgingReportView'
import LoanDisbursmentReport from './views/containers/LoanDisbursmentReportView'
import LoanRepaymentsReport from './views/containers/LoanRepaymentsReportView'
import BalanceSheetReport from './views/containers/BalanceSheetView'
import ShareTransferView from './views/containers/ShareTransferView'
import SubAccountTransferView from './views/containers/SubAccountTransferView'
import FinancialStatementReport from './views/containers/FinancialStatementReportView'
import SaccoDetail from './views/containers/saccoDetailView'
import AccountList from './views/containers/AccountsListView'
import AccountDetail from './views/containers/AccountDetailView'
import SendSMSMessageView from './views/containers/SendSMSMessageView'
import GroupList from './views/containers/GroupsListView'
import GroupDetail from './views/containers/GroupDetailView'
import UserBalancesReport from './views/containers/UserBalancesReportView'

import InterBranchAccountDepositView from './views/containers/InterBranchAccountDepositView'
import InterBranchAccountWithdrawView from './views/containers/InterBranchAccountWithdrawView'
import InterBranchFinancialStatementReportView from './views/containers/InterBranchFinancialStatementReportView'
import  InterBranchMoneyTransferForm from './views/components/InterBranchMoneyTransferForm'

import InterBranchTransactionReportView from './views/containers/InterBranchTransactionReportView'
import  InterBranchMoneyTransfersReportView from './views/containers/InterBranchMoneyTransfersReportView'

import TransactionReportPerChannel from './views/containers/TransactionReportViewPerChannel'
import LoggedInList from './views/containers/LoggedInAccountsListView'
import BankAccountList from './views/containers/BankAccountListView'

import SaccoMembersPerUserReport from './views/containers/SaccoMembersPerUserReportView'
import SafeBankTransfersReport from './views/containers/SafeBankTransfersReportView'
import SafeUserTransferReport from './views/containers/SafeUserTransfersReportView'
import SaccoSmsUsagestatementreport from './views/containers/saccosmsusagestatementreport'

import MemberReserveReport from './views/containers/MemberReserveReportView'

import  LoanDailyRepaymentView from './views/containers/LoanDailyRepaymentView'

import AccountDepositSharesView from './views/containers/AccountDepositSharesView'

import FinancialStatementSharesReport from './views/containers/FinancialStatementSharesReportView'

import IncomeStatementsDividendsReport from './views/containers/IncomeStatementDividendsView'

import TransactionList from './views/containers/TransactionsListView'
import LoanDefaultsReportDaily from './views/containers/LoanDefaultsReportViewDaily'
import MembersReport from './views/containers/MembersReportView'
import  LoanRepaymentDirectView from './views/containers/LoanRepaymentDirectView'
import  PeriodicMemberSummaryReport from './views/containers/PeriodicMemberSummaryReportView'
import CompanyAccountList from './views/containers/CompanyAccountsListView'
import TrialBalanceReport from './views/containers/TrialBalanceView'
import LedgerReport from './views/containers/LedgerReportView'

import DashBoard from './views/containers/DashBoard'
import QuickMenu from './views/containers/QuickMenu'

import LoanFinesReport from './views/containers/LoanFinesReportView'

import  LoanSecurityTransferView from './views/containers/LoanSecurityTransferView'
import SaccoInterAccountTransfer from './views/containers/SaccoInterAccountsTransferView'
import IncomeStatement_periodic_summaryReport from './views/containers/IncomeStatementView_periodic_summary'
import  LoanRejectionList from './views/containers/LoanRejectionListView'

import  SystemLogList from './views/containers/SystemLogsListView'
import  InterAccountTransfersSingle from './views/containers/InterAccountTransferSingleView'
import DistrictList from './views/containers/UgDistrictListView'
import UgDistrictDetail from './views/containers/UgDistrictDetailView'

import SendButchEMailStatementView from './views/containers/SendButchEmailStatementView'
import emailsentReport from './views/containers/emailsentreport'
import ChartOfAccounts from './views/containers/ChartOfAccountsView'
import  UserSaccoAccountList from './views/containers/UserSaccoAccountAttachmentListView'

import FixedDepositLists from './views/containers/FixedDepositListView'
import FixedDepositDetails from './views/containers/FixedDepositDetailsView'
import FixedDepositReport from './views/containers/FixedDepositReportView'
import SalaryRequistionView from './views/containers/SalaryRequistionView'
import SalaryRequistionList from './views/containers/salaryrequisitionListView'
import NSSFReport from './views/containers/NssfReportView'

const SaccoBaseRouter = () =>(
    <div>
        <Route exact path='/' component={DashBoard}  />
        <Route exact path='/quickmenu/' component={QuickMenu}  />
        
        <Route exact path='/idtypes/' component={IDTypeList}  />
        <Route exact path='/membersreport/' component={MembersReport}  />

        <Route exact path='/idtypes/:idtypeID' component={IDTypeDetail}  />
        <Route exact path='/expensecategories/' component={ExpenseCategoryList}  />
        <Route exact path='/expensecategories/:expensecategoryID' component={ExpenseCategoryDetail}  />
        <Route exact path='/staffroles/' component={StaffRoleList}  />
        <Route exact path='/staffroles/:staffroleID' component={StaffRoleDetail}  />
        <Route exact path='/expenses/' component={ExpenseList}  />
        <Route exact path='/expenses/:expenseID' component={ExpenseDetail}  />
        <Route exact path='/assets/' component={AssetList}  />
        <Route exact path='/assets/:assetID' component={AssetDetail}  />
        <Route exact path='/assetcategories/' component={AssetCategoryList}  />
        <Route exact path='/assetcategories/:assetcategoryID' component={AssetCategoryDetail}  />
        <Route exact path='/staffs/' component={StaffList}  />
        <Route exact path='/staffs/:staffID' component={StaffDetail}  />
        <Route exact path='/accounttypes/' component={AccountTypeList}  />
        <Route exact path='/accounttypes/:accountTypeID' component={AccountTypeDetail}  />
        <Route exact path='/loantypes/' component={LoanTypeList}  />
        <Route exact path='/loantypes/:loanTypeID' component={LoanTypeDetail}  />
        <Route exact path='/nonworkingdays/' component={NonWorkingDayList}  />
        <Route exact path='/nonworkingdays/:dayID' component={NonWorkingDayDetail}  />
        <Route exact path='/incomecategories/' component={IncomeCategoryList}  />
        <Route exact path='/incomecategories/:categoryID' component={IncomeCategoryDetail}  />
        <Route exact path='/cashatbank/' component={CashAtBankList}  />
        <Route exact path='/cashatbank/:cashID' component={CashAtBankDetail}  />
        <Route exact path='/externalloans/' component={ExternalLoanList}  />
        <Route exact path='/externalloans/:loanID' component={ExternalLoanDetail}  />
        <Route exact path='/members/' component={MemberList}  />
        <Route exact path='/members/:memberID' component={MemberDetail}  />
        <Route exact path='/deposit/' component={AccountDepositView}  />
        <Route exact path='/withdraw/' component={AccountWithdrawView}  />
        <Route exact path='/loanapplication/' component={LoanApplicationForm}  />
        <Route exact path='/loancalculator/' component={LoanCalculator}  />
        <Route exact path='/loanapplications/' component={LoanApplicationsList}  />
        <Route exact path='/loanapplications/:loanapplicationID' component={LoanApplicationDetail}  />
        <Route exact path='/makeassessment/:loanapplicationID' component={LoanAssessmentForm}  />
        <Route exact path='/loanassessments/' component={LoanAssessmentList}  />
        <Route exact path='/approveloan/:loanapplicationID' component={LoanAppovalForm}  />
        <Route exact path='/loanapprovals/' component={LoanApprovalList}  />
        <Route exact path='/issueloan/:loanapplicationID' component={LoanIssuanceForm}  />
        <Route exact path='/loanissues/' component={LoanIssuanceList}  />
        <Route exact path='/loanissues/:loanissueID' component={LoanIssueDetail}  />
        <Route exact path='/loanrepayment/' component={LoanRepaymentView}  />
        <Route exact path='/incomes/' component={IncomeList}  />
        <Route exact path='/incomes/:incomeID' component={IncomeDetail}  />
        <Route exact path='/loanguarantors/:applicationID' component={LoanGuarantorsList}  />
        <Route exact path='/loanconsents/:applicationID' component={LoanConsentsList}  />
        <Route exact path='/accountopeningitems/:accountTypeID' component={OpeningItemList}  />
        <Route exact path='/tillsheet/' component={TillSheetReport}  />
        <Route exact path='/accounttransactions/' component={TransactionReport} />
        <Route exact path='/memberbalances/' component={MemberSavingsReport} />
        <Route exact path='/incomestatement/' component={IncomeStatementReport} />
        <Route exact path='/dayloanreport/' component={DayLoanReport} />
        <Route exact path='/loanoutstanding/' component={OutStandingLoanReport} />
        <Route exact path='/loanrepaymentstatus/' component={LoanRepaymentStatusReport} />
        <Route exact path='/loandefaults/' component={LoanDefaultsReport} />
        <Route exact path='/loanaging/' component={LoanAgingReport} />
        <Route exact path='/loandisbursement/' component={LoanDisbursmentReport} />
        <Route exact path='/loanrepaymentreport/' component={LoanRepaymentsReport} />
        <Route exact path='/balancesheet/' component={BalanceSheetReport} />
        <Route exact path='/sharetransfer/' component={ShareTransferView} />
        <Route exact path='/subaccounttransfer/' component={SubAccountTransferView} />
        <Route exact path='/financialstatement/' component={FinancialStatementReport} />
        <Route exact path='/companyprofile/' component={SaccoDetail} />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/useraccounts/:accountID' component={AccountDetail} />
        <Route exact path='/messaging/' component={SendSMSMessageView} />
        <Route exact path='/groups/' component={GroupList} />
        <Route exact path='/groups/:groupID' component={GroupDetail}  />
        <Route exact path='/userbalances/' component={UserBalancesReport} />

        <Route exact path='/interbranchdeposit/' component={InterBranchAccountDepositView}  />
        <Route exact path='/interbranchwithdraw/' component={InterBranchAccountWithdrawView}  />
        <Route exact path='/interbranchfinancialstatement/' component={InterBranchFinancialStatementReportView}  />

        <Route exact path='/interbranchmoneytransfer/' component={InterBranchMoneyTransferForm} />
        
        <Route exact path='/interbranchtransactionsreport/' component={InterBranchTransactionReportView} />
        <Route exact path='/interbranchmoneytransferreport/' component={InterBranchMoneyTransfersReportView} />

        <Route exact path='/accounttransactionschannel/' component={TransactionReportPerChannel} />
        <Route exact path='/loggedinusers/' component={LoggedInList} />
        <Route exact path='/bankaccounts/' component={BankAccountList} />

        <Route exact path='/memberperuserreport/' component={SaccoMembersPerUserReport} />
        <Route exact path='/safebanktransfersreport/' component={SafeBankTransfersReport} />
        <Route exact path='/safeusertransfersreport/' component={SafeUserTransferReport} />
        <Route exact path='/saccosmsusagestatement/' component={SaccoSmsUsagestatementreport} />

        <Route exact path='/loanreservereport/' component={MemberReserveReport} />
        <Route exact path='/loandailyrepayment/' component={LoanDailyRepaymentView}  />

        <Route exact path='/depositshares/' component={AccountDepositSharesView}  />
        <Route exact path='/sharesstatementreport/' component={FinancialStatementSharesReport}  />
        <Route exact path='/dividendscalculator/' component={IncomeStatementsDividendsReport}  />
       
        <Route exact path='/alltransactions/' component={TransactionList}  />
        <Route exact path='/salaryrequisition/' component={SalaryRequistionView}  />
        <Route exact path='/loandailydefaultsreport/' component={LoanDefaultsReportDaily}  />
        <Route exact path='/loanrepaymentdirect/' component={LoanRepaymentDirectView}  />

        <Route exact path='/periodicmember_summaryreport/' component={PeriodicMemberSummaryReport}  />
        <Route exact path='/companyaccounts/' component={CompanyAccountList}  />
        <Route exact path='/trialbalance/' component={TrialBalanceReport}  />
        <Route exact path='/ledgerreport/' component={LedgerReport}  />

        <Route exact path='/loanfines/' component={LoanFinesReport} />
        <Route exact path='/ugdistricts/' component={DistrictList}  />
        <Route exact path='/ugdistricts/:districtID' component={UgDistrictDetail}  />
        
        <Route exact path='/loansecuritytransfer/' component={LoanSecurityTransferView}  />
        <Route exact path='/org_interaccount_transfer/' component={SaccoInterAccountTransfer}  />
        <Route exact path='/periodic_performance_report/' component={IncomeStatement_periodic_summaryReport}  />
        <Route exact path='/rejectedloanapplications/' component={LoanRejectionList}  />
        <Route exact path='/systemlogs/' component={SystemLogList}  />
        <Route exact path='/interaccounttransfer/' component={InterAccountTransfersSingle}  />

        <Route exact path='/batchemail_statementsending/' component={SendButchEMailStatementView}  />
        <Route exact path='/sentemailsreport/' component={emailsentReport}  />
        <Route exact path='/chartof_accounts/' component={ChartOfAccounts}  />
        <Route exact path='/user_saccoaccounts/:accountID' component={UserSaccoAccountList}  />

        <Route exact path='/fixeddeposits/' component={FixedDepositLists}  />
        <Route exact path='/fixeddeposits/:depositid' component={FixedDepositDetails}  />
        <Route exact path='/fixeddepositreport/' component={FixedDepositReport}  />

        <Route exact path='/salaryrequisition/' component={SalaryRequistionView}  />
        <Route exact path='/salaryrequisitionlist/' component={SalaryRequistionList} />
        <Route exact path='/nssfreport/' component={NSSFReport} />

    </div>
);

export default SaccoBaseRouter;


