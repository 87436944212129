import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card,Spin,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

import {
  Col,
  Row,
} from 'reactstrap';
var CryptoJS = require("crypto-js");

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var sacco= ''
var username=''
var token= ''


class ActivateMemberForm extends React.Component {
  state = {
    memberid:'',  
    activationmode:'',
    datarequested:false,
    datasubmittedsuccessfully:false,
  }

  componentDidMount(){
    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const memberid=this.props.memberid;
    this.setState({memberid:memberid})
  }


  //submit button pressed
  onFinish=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('memberid', this.state.memberid);
    form_data.append('activationmode', this.state.activationmode);
    form_data.append('sacco', sacco);
    form_data.append('username', username);

    ///make a post request now
      axios.post(serverconfig.backendserverurl+'/customqueries/activateaccount', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      message.info(res.data.message)
      this.setState({datarequested:false})
      if (res.data.message==="success"){
        this.setState({datasubmittedsuccessfully:true})
      }
    })
    .catch(error => console.log(error))

    }

    handleactivationmodeChange= (e) => {
       this.setState({activationmode:e})
    }



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successful"
            subTitle="Operation successful"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
            <Card>
    
            <Form
                  name="activationform"
                  onFinish={(event) => this.onFinish(event)}
              >
    
    
                <FormItem 
                      label="Mode"
                      name="activationmode"
                      rules={[
                        {
                          required: true,
                          message: 'Please select activation mode',
                        },
                      ]}
                      >
                          <Select 
                          value={this.state.activationmode} 
                          onChange={this.handleactivationmodeChange}
                          placeholder=" Mode" >
                                <Option value='firstactivation'>First time activation</Option>
                                <Option value='reactivation'>Reactivation</Option>
                                <Option value='deactivate'>Deactivate member</Option>

                          </Select>
                </FormItem>
    
              
                <FormItem>
                  <Button  type="primary" htmlType="submit">Submit</Button>
                </FormItem>
                </Form>
            </Card>
    
     
          </div>
        );

      }






      

    }

    
  }

}


export default ActivateMemberForm;



