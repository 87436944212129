import React from 'react';
import MemberForm from '../components/MemberForm'
import ActivateMemberForm from '../components/ActivateMemberForm'

import axios from 'axios'
import { Table, Input, Button,Select,Collapse,DatePicker,Typography,Form,Space,Divider,Popover,Popconfirm,message,Descriptions,Modal,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,PrinterOutlined,FundViewOutlined,EditOutlined,WalletFilled,LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../serverconn'
import ExpensePaymentForm from '../components/ExpensePaymentForm'
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

import {
  Col,
  Row,
} from 'reactstrap';

var CryptoJS = require("crypto-js");

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Title } = Typography;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

function callback(key) {
  console.log(key);
}

var token= ''
var sacco= ''
var bizuserid= ''

class ReportToPrint extends React.Component {

  state = {
    companyprofile:{},
  };

  componentDidMount(){

    if(localStorage.getItem("sacco")){
       sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
       bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
       sacco= ''
       bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })


  }

  render() {
    return (
      <div style={{padding:20}}>
<Row> 
              <Col xs="2" sm="2" lg="2">
              <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.sacco_logo} />
            
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.sacco_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                {
                this.state.companyprofile.RegisteredPhoneNumber2==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber2
                }
                {
                this.state.companyprofile.RegisteredPhoneNumber3==""?
                "":","+this.state.companyprofile.RegisteredPhoneNumber3
                }
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>Wesite: {this.state.companyprofile.website}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                <h5>Country: {this.state.companyprofile.country} || Currency: {this.state.companyprofile.currency}</h5>
              
                </Col>
         </Row>


           <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center',fontWeight:'bolder'}}>NSSF REPORT FROM: {this.props.dateone}  TO: {this.props.datetwo}</h3>

           <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.props.nssflist.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.nssfbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>   

      </div>
    );
  }
}


class NSSFReport extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    nssflist: [],
    viewpaymentmodal:false,
    expensedetails:{},
    companyprofile:{},
    userprofile:{},
    datarequested:true,
    memberactivationformvisible:false,
    selectedmemberid:'',
    totalreserve:0,
    dateone:'',
    datetwo:'',


  };

  componentDidMount(){
      if(localStorage.getItem("sacco")){
        sacco= CryptoJS.AES.decrypt(localStorage.getItem("sacco") , 'my-secret-key@12345').toString(CryptoJS.enc.Utf8)
        bizuserid= CryptoJS.AES.decrypt(localStorage.getItem("bizuserid") , 'my-secret-key@1234').toString(CryptoJS.enc.Utf8)
    }else{
        sacco= ''
        bizuserid= ''
    }
    
    if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
    }else{
        token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/saccos/${sacco}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

        this.setState({datarequested:false})

    })


}

handledatechange= (date, dateString) =>{
  this.setState({ dateone: dateString[0]});
  this.setState({ datetwo: dateString[1]});
} 

searchSales=()=>{

  let form_data = new FormData();
  form_data.append('dateone', this.state.dateone);
  form_data.append('datetwo', this.state.datetwo);
  form_data.append('sacco', sacco);
  
  console.log(this.state.entrant)
    
  if(this.state.dateone===''||this.state.datetwo===''){
    alert("Please  date missing")
  }else{
      this.setState({datarequested:true})

      //Now submit sale data to database
      axios.post(serverconfig.backendserverurl+'/customqueries/getNSSFreport', form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
      .then(res =>{
        this.setState({datarequested:false})
        this.setState({nssflist:JSON.parse(res.data.report)})
      } 
      )
      .catch(error => console.log(error))   
  }

  }


  render() {
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
            <div style={{display: "none"}}>
               &nbsp;&nbsp;
              <ReportToPrint
              nssflist={this.state.nssflist}
              totalreserve={this.state.totalreserve}
              dateone={this.state.dateone}
              datetwo={this.state.datetwo}
              ref={el => (this.componentRef = el)} /> 
             </div>


            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={<h4 style={{fontWeight:'bolder'}}> NSSF REPORT</h4>} key="1">
           

              <Form  layout="inline" >

                  <FormItem label="Date Range">
                      <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                  </FormItem>

                  <FormItem>
                  <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                  </FormItem> 

              </Form>

               <reactstrp.Table bordered>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Account</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                      {this.state.nssflist.map(
                        (item)=>(
                          <tr>
                          <td>{item.date}</td>
                          <td>{item.account}</td>
                          <td>{<CurrencyFormat value={item.debit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.credit} displayType={'text'} thousandSeparator={true}/>}</td>
                          <td>{<CurrencyFormat value={item.nssfbalance} displayType={'text'} thousandSeparator={true}/>}</td>

                          </tr>
                        ))}

                    </tbody>
                </reactstrp.Table>              
           
                <div style={{display:'flex',justifyContent:'flex-end',alignSelf:'flex-end',alignItems:'flex-end'}}>
                <ReactToPrint
                  trigger={() => 
                  <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                  Print
                  </Button>
                  }
                  content={() => this.componentRef}
                  />
              </div>

            </Panel>
            </Collapse>
        </div>
    )

    }

  }
}

export default NSSFReport; 
